<template>
  <b-row
    v-if="account.isAdmin"
    class="match-height"
  >

    <!--  Template Analytics-->
    <b-col md="5">
      <b-card
        no-body
        class="card-h"
      >
        <b-card-header class="p-4">
          <b-card-title>
            Template Analytics
          </b-card-title>
        </b-card-header>
        <div class="d-flex flex-column justify-content-between h-100">
          <b-table
            id="template-analytics-table"
            :items="templateAnalyticsItems"
            :fields="templateAnalyticsFields"
            :per-page="perPage"
            :current-page="currentPageAnalytics"
            responsive
            show-empty
            empty-html="<span>Check out our message template feature <a class='underline' target='_blank' href='https://intercom.help/surfe/en/articles/8367715-using-message-templates'>here</a></span>"
            class="px-4 py-0 mb-0 text-center table-h"
          >
            <template #cell(templateName)="row">
              <b>{{ row.item.templateName }}</b>
              <span
                v-b-tooltip.hover
                title="Template Owner"
                class="ml-1 font-small-1"
              >({{ row.item.templateOwnerName }})</span>
            </template>

            <template #cell(topUserName)="row">
              <span
                v-b-tooltip.hover
                :title="getTopUserTooltip(row.item.topUserCount)"
              >{{ row.item.topUserName }}</span>
            </template>

            <template #cell(replyRate)="row">
              {{ Math.floor(row.item.replyRate * 100) }}%
            </template>
          </b-table>

          <b-pagination
            v-if="templateAnalyticsItems.length > perPage"
            v-model="currentPageAnalytics"
            :total-rows="templateAnalyticsItems.length"
            :per-page="perPage"
            aria-controls="template-analytics-table"
            align="center"
            class="mt-5 mb-0 pagination-sm"
          />

          <p class="p-4 mb-0 text-muted font-small-2">
            Analytics only gathered when <b>Template Analytics</b> feature enabled.
          </p>
        </div>
      </b-card>

    </b-col>

    <!--  User Analytics-->
    <b-col md="7">
      <b-card
        no-body
        class="card-h"
      >
        <b-card-header class="p-4">
          <b-card-title>
            User Analytics
          </b-card-title>
        </b-card-header>

        <div class="d-flex flex-column justify-content-between h-100">
          <b-table
            id="user-analytics-table"
            :items="userAnalyticsItems"
            :fields="userAnalyticsFields"
            :per-page="perPage"
            :current-page="currentPageUsers"
            responsive
            show-empty
            empty-text="Start syncing messages to see your results"
            class="px-4 py-0 mb-0 text-center table-h"
          >
            <template #cell(userName)="row">
              <b>{{ row.item.userName }}</b>
            </template>

            <template #cell(contactReplyRate)="row">
              {{ Math.floor(row.item.contactReplyRate * 100) }}%
            </template>

            <template #cell(averageResponseTimeInMSec)="row">
              <span v-if="row.item.averageResponseTimeInMSec !== '' && row.item.averageResponseTimeInMSec !== 0">
                {{ moment().add(row.item.averageResponseTimeInMSec, 'millisecond').fromNow(true) }}
              </span>
            </template>
          </b-table>

          <b-pagination
            v-if="userAnalyticsItems.length > perPage"
            v-model="currentPageUsers"
            :total-rows="userAnalyticsItems.length"
            :per-page="perPage"
            aria-controls="user-analytics-table"
            align="center"
            class="mt-2 mb-0 pagination-sm"
          />

          <p class="p-4 mb-0 text-muted font-small-2">
            Analytics only gathered when <b>Template Analytics</b> feature enabled.
          </p>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex'
import {
  BCard,
  BTable,
  BCol,
  BPagination,
  BRow,
  BCardHeader,
  BCardTitle,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BCol,
    BRow,
    BPagination,
    BCardHeader,
    BCardTitle,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      perPage: 5,
      currentPageUsers: 1,
      currentPageAnalytics: 1,
      templateAnalyticsFields: [
        { key: 'templateName', sortable: true, class: 'text-center' },
        {
          key: 'topUserName', sortable: true, label: 'Top User', class: 'text-center',
        },
        {
          key: 'totalUse', sortable: true, label: 'Times used', class: 'text-center',
        },
        { key: 'replyRate', sortable: true, class: 'text-center' },
      ],
      userAnalyticsFields: [
        { key: 'userName', sortable: true, class: 'text-center' },
        {
          key: 'totalSynced', sortable: true, label: 'Messages synced', class: 'text-center',
        },
        {
          key: 'averageResponseTimeInMSec', sortable: true, label: 'Avg resp. time', class: 'text-center',
        },
        { key: 'contactReplyRate', sortable: true, class: 'text-center' },
      ],
    }
  },
  computed: {
    ...mapState({
      templateAnalyticsItems: state => state.api.templateAnalytics,
      userAnalyticsItems: state => state.api.userAnalytics,
      account: state => state.api.account,
    }),
  },
  methods: {
    getTopUserTooltip(count) {
      return `Used ${count} ${count === 1 ? 'time' : 'times'}`
    },
  },
}
</script>
<style lang="scss">
.underline {
  text-decoration: underline;
}
</style>

<template>
  <div>
    <!-- Actions graph (with filters) -->
    <div class="mb-6">
      <b-dropdown
        :text="duration"
        variant="secondary"
      >
        <b-dropdown-item
          v-for="(value, key) in durations"
          :key="key"
          @click="setDuration(key, value)"
        >
          {{ key }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <statistic-card-with-area-chart
      v-if="actions"
      icon="BarChart2Icon"
      :statistic="totalActions"
      statistic-title="Actions performed with Surfe"
      :chart-data="actionSeries"
      :actions="actionTypes"
    />

    <b-row class="match-height">
      <!-- Hours saved -->
      <b-col sm="4">
        <medal-card
          :name="account.name"
          :company-name="account.companyName"
          :saved-time="savedTime"
          :action-stats="account.actionStats"
        />
      </b-col>

      <!-- Main statistics -->
      <b-col sm="8">
        <b-overlay
          no-center
          :show="account.companyPlan === 'FREE'"
        >
          <statistics :data="createStatistics()" />

          <template #overlay>
            <div class="statistic-overlay">
              <a href="/billing">
                <h4>
                  <feather-icon
                    icon="StarIcon"
                    class="mr-2"
                  />Upgrade plan to view Statistics
                </h4>
              </a>
            </div>
          </template>
        </b-overlay>
      </b-col>
    </b-row>

    <message-template-analytics v-if="templateAnalyticsFlag" />
    <connection-request-analytics v-if="isLinkedinConnectionAnalyticsEnabled()" />

    <b-overlay
      :show="!account.features.enableAdvancedAnalytics"
      rounded="sm"
    >
      <b-row class="match-height">
        <!-- Industries histogram -->
        <b-col sm="4">
          <apex-bar-chart :data="exportData.industryData" />
        </b-col>

        <!-- Countries -->
        <b-col sm="8">
          <b-card no-body>
            <b-card-header>
              <b-card-title>World map</b-card-title>
            </b-card-header>

            <b-card-body>
              <MapChart
                style="height:400px"
                :country-data="exportData.countries"
                high-color="#E0E5E6"
                low-color="#EBEFF0"
                country-stroke-color="#889BA0"
                default-country-fill-color="#ffffff"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <template #overlay>
        <div class="text-center">
          <a href="/change-plan">
            <h4 id="cancel-label">
              <feather-icon
                icon="StarIcon"
                class="mr-2"
              />Upgrade Enterprise
              plan to see more analytics
            </h4>
          </a>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  BCard,
  BCardTitle,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BOverlay,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import MapChart from 'vue-map-chart'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import MessageTemplateAnalytics from '@/views/analytics/MessageTemplateAnalytics.vue'
import ConnectionRequestAnalytics from '@/views/analytics/ConnectionRequestAnalytics.vue'
import { trackEvent } from '@core/utils/tracking'
import MedalCard from '../../@core/components/statistics-cards/MedalCard.vue'
import Statistics from './Statistics.vue'
import ApexBarChart from '../../@core/components/charts/ApexBarChart.vue'

export default {
  components: {
    MessageTemplateAnalytics,
    ConnectionRequestAnalytics,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BOverlay,
    BDropdown,
    BDropdownItem,
    StatisticCardWithAreaChart,
    MedalCard,
    Statistics,
    MapChart,
    ApexBarChart,
  },
  data() {
    const templateAnalyticsFlag = process.env.VUE_APP_TEMPLATE_USER_ANALYTICS_ENABLED === 'true'
    return {
      duration: 'Last 14 days',
      durations: {
        'Last 14 days': 14,
        'Last month': 30,
        'Last 3 months': 90,
        'Last year': 365,
      },
      actionTypes: {
        'Contacts added': 'ADD_LEAD',
        'Messages sent': 'SYNC_CONV',
        'CRM Profiles Viewed': 'VIEW_LEAD',
        'Companies added': 'ADD_COMPANY',
      },
      templateAnalyticsFlag,
    }
  },
  computed: {
    ...mapState({
      actions: state => state.api.actions,
      account: state => state.api.account,
      featureFlags: state => state.api.featureFlags,
    }),
    totalActions() {
      return this.actions.reduce((a, b) => a + b, 0)
    },
    actionSeries() {
      return [{ name: 'Actions', data: this.actions }]
    },
    savedTime() {
      return this.timeInString(this.account.actionStats.savedMinutes)
    },
    exportData() {
      return this.prepareExportData()
    },
  },
  watch: {
    $route(to) {
      let { actionType } = to.query
      if (!actionType) actionType = ''
      const { duration } = to.query
      this.$store.dispatch('api/loadUsers')
      this.$store.dispatch('api/loadActions', { actionType, duration })
    },
  },
  async mounted() {
    if (!this.account.features.enableNonAdminViewAnalytics && !this.account.isAdmin) this.$router.push('/')
    await this.$store.dispatch('api/loadFeatureFlags')

    const { duration } = this.$route.query
    if (!duration) return
    const dropdown = Object.keys(this.durations).find(
      key => this.durations[key] === parseInt(duration),
    )
    this.duration = dropdown
    if (!dropdown) this.duration = 'Custom duration'
  },
  methods: {
    async setDuration(key, value) {
      this.duration = key
      await this.trackDurationChange(value)
      this.$router
        .push({
          path: this.$route.path,
          query: { ...this.$route.query, duration: value },
        })
        .catch(() => {})
    },
    timeInString(t) {
      if (t > 60) {
        const hours = parseInt(t / 60)
        return hours > 1 ? `${hours} hours` : '1 hour'
      }
      return `${t} minutes`
    },
    async trackDurationChange(duration) {
      switch (duration) {
        case 14:
          await trackEvent({ type: 'dashboard-analytics-period_changed', props: { period: '14_DAYS' } })
          break
        case 30:
          await trackEvent({ type: 'dashboard-analytics-period_changed', props: { period: '1_MONTH' } })
          break
        case 90:
          await trackEvent({ type: 'dashboard-analytics-period_changed', props: { period: '3_MONTHS' } })
          break
        case 365:
          await trackEvent({ type: 'dashboard-analytics-period_changed', props: { period: '1_YEAR' } })
          break
        default:
          break
      }
    },
    prepareExportData() {
      if (!this.account.features.enableAdvancedAnalytics) return this.createDummyData()

      // Prepare country data
      const countries = this.account.exportCountryStats.countries.reduce((acc, curr) => ({
        ...acc,
        [curr.country]: curr.count,
      }), {})

      // Prepare industry data
      const industryData = {
        values: [],
        categories: [],
      }
      const { industries } = this.account.exportIndustryStats
      for (let i = 0; i < industries.length; i++) {
        industryData.categories.push(industries[i].industry)
        industryData.values.push(industries[i].count)
      }

      return { countries, industryData }
    },
    createDummyData() {
      return {
        countries: {
          FR: 20,
          DE: 20,
          RU: 20,
          TR: 20,
          EG: 10,
          ID: 10,
          US: 10,
          BR: 10,
          MK: 10,
        },
        industryData: {
          values: [1200, 900, 750, 400, 200],
          categories: [
            'Internet',
            'Entertainment',
            'Marketing',
            'Travel',
            'Retail',
          ],
        },
      }
    },
    createStatistics() {
      return [
        {
          icon: 'UserPlusIcon',
          color: 'light-primary',
          title: this.account.actionStats.contactsAdded,
          subtitle: 'Contacts added',
        },
        {
          icon: 'RefreshCwIcon',
          color: 'light-info',
          title: this.account.actionStats.messagesSynced,
          subtitle: 'Messages synced',
        },
        {
          icon: 'MailIcon',
          color: 'light-danger',
          title: this.account.actionStats.enrichmentsDone,
          subtitle: 'Enrichments done',
        },
        {
          icon: 'CopyIcon',
          color: 'light-success',
          title: this.account.actionStats.templatesUsed,
          subtitle: 'Templates used',
        },
      ]
    },
    isLinkedinConnectionAnalyticsEnabled() {
      return this.featureFlags?.flags?.LINKEDIN_CONNECTIONS_ANALYTICS?.value
    },
  },
}
</script>

<style lang="scss">

.statistic-overlay {
  position: absolute;
  bottom: 40px;
  left: 20px
}

</style>

<template>
  <div>
    <b-card v-if="name">
      <b-row>
        <b-col
          sm="6"
          class="pr-0"
        >
          <p class="font-weight-bolder font-small-3 mb-2">
            Congratulations {{ name }}!
          </p>

          <b-card-text class="font-weight-bolder font-small-3 text-info text-lighten-1">
            {{ companyName }} saved
          </b-card-text>

          <h4 class="mt-8 mb-5 text-primary text-darken-1">
            <b-card-text>{{ kFormatter(savedTime) }}</b-card-text>
          </h4>

          <b-button
            v-b-modal.more-stats
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="trackViewMoreStats"
          >
            View More Stats
          </b-button>
        </b-col>

        <b-col
          sm="6"
          class="pl-0 position-relative d-flex align-items-center justify-content-end"
        >
          <b-img
            fluid
            :src="require('@/assets/images/image-backdrop-blue.svg')"
            class="position-absolute pl-7_5 pr-2 pb-2_5"
            alt="svg img"
          />
          <b-img
            fluid
            :src="require('@/assets/images/salesman-surfer.png')"
            :srcset="require('@/assets/images/salesman-surfer.png') + ' 1x, ' + require('@/assets/images/salesman-surfer@2x.png') + ' 2x'"
            class="position-absolute zindex-1"
            alt="svg img"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="more-stats"
      title="More stats"
      ok-title="Close"
      ok-only
      centered
    >
      <div>
        <p class="mb-4_5 font-weight-bolder">
          Action breakdown
        </p>

        <span
          v-if="actionStats.contactsViewed || actionStats.contactsAdded"
          class="font-small-3"
        >
          <p
            v-if="actionStats.contactsAdded"
            class="mb-2"
          >
            Contacts synced: <strong>{{ actionStats.contactsAdded }}</strong>
            ({{ computeActionValue(actionStats.contactsAdded, timeToAddLead) }} minutes saved)
          </p>
          <p
            v-if="actionStats.contactsViewed"
            class="mb-2"
          >
            Contacts viewed: <strong>{{ actionStats.contactsViewed }}</strong>
            ({{ computeActionValue(actionStats.contactsViewed, timeToViewLead) }} minutes saved)
          </p>
        </span>

        <span
          v-if="actionStats.companiesViewed || actionStats.companiesAdded"
          class="font-small-3"
        >
          <p
            v-if="actionStats.companiesAdded"
            class="mb-2"
          >
            Companies synced: <strong>{{ actionStats.companiesAdded }}</strong>
            ({{ computeActionValue(actionStats.companiesAdded, timeToAddCompany) }} minutes saved)
          </p>
          <p
            v-if="actionStats.companiesViewed"
            class="mb-2"
          >
            Companies viewed: <strong>{{ actionStats.companiesViewed }}</strong>
            ({{ computeActionValue(actionStats.companiesViewed, timeToViewCompany) }} minutes saved)
          </p>
        </span>

        <span
          v-if="actionStats.messagesSynced"
          class="font-small-3"
        >
          <p class="mb-2">
            Messages synced: <strong>{{ actionStats.messagesSynced }}</strong>
            ({{ computeActionValue(actionStats.messagesSynced, timeToSyncConv) }} minutes saved)
          </p>
        </span>

        <span
          v-if="actionStats.enrichmentsDone"
          class="font-small-3"
        >
          <p class="mb-2">
            Enrichment: <strong>{{ actionStats.enrichmentsDone }}</strong> enrichments done
            ({{ computeActionValue(actionStats.enrichmentsDone, timeToEnrichLead) }} minutes saved)
          </p>
        </span>

        <p class="mb-5 mt-9 font-weight-bolder">
          Reference time for each action
        </p>

        <b-table
          striped
          hover
          tabel-variant="secondary"
          :items="individualActionItems()"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BImg, BModal, BTable, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import { trackEvent } from '@core/utils/tracking'

export default {
  components: {
    BCard,
    BCardText,
    BImg,
    BButton,
    BModal,
    BTable,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    name: {
      type: String,
      default: () => '',
    },
    companyName: {
      type: String,
      default: () => '',
    },
    savedTime: {
      type: String,
      default: () => '',
    },
    actionStats: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timeToAddLead: 2.2,
      timeToEnrichLead: 7.9,
      timeToSyncConv: 3.5,
      timeToUpdateLead: 1.4,
      timeToAddNotes: 1.4,
      timeToAddLeadSalesNav: 2.2,
      timeToViewLead: 1.0,
      timeToViewLeadSalesNav: 1.2,
      timeToSyncConvSalesNav: 4.3,
      timeToAddCompany: 2.4,
      timeToViewCompany: 1.0,
      timeToUpdateCompany: 1.2,
    }
  },
  methods: {
    kFormatter,
    computeActionValue(action, time) {
      return parseInt(action * time)
    },
    computeTimeInString(time) {
      const timeString = `${time} minute`
      return time > 1 ? `${timeString}s` : timeString
    },
    async trackViewMoreStats() {
      await trackEvent({ type: 'dashboard-analytics-view_more_clicked' })
    },
    individualActionItems() {
      return [
        { action: 'Add contact', averageTimeInMinutes: this.computeTimeInString(this.timeToAddLead) },
        { action: 'View contact', averageTimeInMinutes: this.computeTimeInString(this.timeToViewLead) },
        { action: 'Add company', averageTimeInMinutes: this.computeTimeInString(this.timeToAddCompany) },
        { action: 'View company', averageTimeInMinutes: this.computeTimeInString(this.timeToViewCompany) },
        { action: 'Message sync', averageTimeInMinutes: this.computeTimeInString(this.timeToSyncConv) },
        { action: 'Enrichment', averageTimeInMinutes: this.computeTimeInString(this.timeToEnrichLead) },
      ]
    },
  },
}
</script>

<template>
  <b-row
    v-if="account.isAdmin"
    class="match-height"
  >
    <b-col>
      <b-card
        no-body
        class="card-h"
      >
        <b-card-header class="p-4">
          <b-card-title>
            Connection Request Analytics
          </b-card-title>
        </b-card-header>
        <div class="d-flex flex-column justify-content-between h-100">
          <b-table
            id="connection-request-analytics-table"
            :items="connectionRequestAnalytics"
            :fields="connectionRequestAnalyticsFields"
            :per-page="perPage"
            :current-page="currentPageAnalytics"
            responsive
            show-empty
            class="px-4 py-0 mb-0 text-center table-h"
          />

          <b-pagination
            v-if="connectionRequestAnalytics && connectionRequestAnalytics.length > perPage"
            v-model="currentPageAnalytics"
            :total-rows="connectionRequestAnalytics.length"
            :per-page="perPage"
            aria-controls="connection-request-analytics-table"
            align="center"
            class="mt-5 mb-0 pagination-sm"
          />

          <p class="p-4 mb-0 text-muted font-small-2">
            Connections Request Analytics only gathered when <b>Log Linkedin connection requests</b> feature enabled.
          </p>
        </div>
      </b-card>

    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex'
import {
  BCard,
  BTable,
  BCol,
  BPagination,
  BRow,
  BCardHeader,
  BCardTitle,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BCol,
    BRow,
    BPagination,
    BCardHeader,
    BCardTitle,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      perPage: 5,
      currentPageUsers: 1,
      currentPageAnalytics: 1,
      connectionRequestAnalyticsFields: [
        {
          key: 'name', sortable: true, label: 'Representative name', class: 'text-center',
        },
        {
          key: 'totalConnectionRequestsSent', sortable: true, label: 'Connection Requests Sent', formatter: this.formatValue, class: 'text-center',
        },
        {
          key: 'totalConnectionRequestsAccepted', sortable: true, label: 'Connection Requests Accepted', formatter: this.formatValue, class: 'text-center',
        },
        {
          key: 'acceptanceRate', sortable: true, label: 'Acceptance Rate (%)', formatter: this.formatValue, class: 'text-center',
        },
        {
          key: 'sentToday', sortable: true, label: 'Sent Today', formatter: this.formatValue, class: 'text-center',
        },
        {
          key: 'sentThisWeek', sortable: true, label: 'Sent this Week', formatter: this.formatValue, class: 'text-center',
        },
        {
          key: 'sentThisMonth', sortable: true, label: 'Sent this Month', formatter: this.formatValue, class: 'text-center',
        },
        {
          key: 'sentThisQuarter', sortable: true, label: 'Sent this Quarter', formatter: this.formatValue, class: 'text-center',
        },
        {
          key: 'sentThisYear', sortable: true, label: 'Sent this Year', formatter: this.formatValue, class: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      connectionRequestAnalytics: state => state.api.connectionRequestAnalytics,
      account: state => state.api.account,
    }),
  },
  methods: {
    formatValue(value) {
      return value === '0' || value === '0.00' ? '-' : value
    },
  },
}
</script>

<template>
  <b-card no-body>
    <b-card-body
      class="pb-0"
      style="display:flex"
    >
      <b-avatar
        class="mb-1 mr-2"
        :variant="`light-${color}`"
        size="45"
      >
        <feather-icon
          size="21"
          :icon="icon"
        />
      </b-avatar>

      <div class="truncate">
        <h3 class="mb-1 font-weight-bolder text-primary text-darken-1">
          {{ statistic }}
        </h3>
        <span class="font-small-2 text-info text-lighten-2 font-weight-bold">{{ statisticTitle }}</span>
      </div>

      <div
        v-if="actions"
        class="position-absolute mt-2 mr-2"
        style="right: 0; top: 0;"
      >
        <b-dropdown
          :text="headline"
          variant="secondary"
        >
          <b-dropdown-item
            v-if="this.$route.query.actionType"
            @click="resetActionType()"
          >
            All
          </b-dropdown-item>
          <b-dropdown-item
            v-for="(value, key) in actions"
            :key="key"
            @click="setActionType(key, value)"
          >
            {{ key }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </b-card-body>

    <vue-apex-charts
      type="area"
      height="100"
      width="100%"
      :options="chartOptionsComputed"
      :series="chartData"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BAvatar,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { trackEvent } from '@core/utils/tracking'
import { areaChartOptions } from './chartOptions'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardBody,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: null,
    },
    actions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      headline: 'Filter Actions',
    }
  },
  computed: {
    chartOptionsComputed() {
      if (this.chartOptions === null) {
        const options = JSON.parse(JSON.stringify(areaChartOptions))
        options.theme.monochrome.color = $themeColors[this.color]
        return options
      }
      return this.chartOptions
    },
  },
  async mounted() {
    let { actionType } = this.$route.query
    if (!actionType) actionType = ''

    const { duration } = this.$route.query

    const headline = Object.keys(this.actions).find(key => this.actions[key] === actionType)
    this.headline = headline
    if (!headline) this.headline = 'Filter Actions'

    this.$store.dispatch('api/loadUsers')
    this.$store.dispatch('api/loadActions', { actionType, duration })
  },
  methods: {
    async setActionType(key, value) {
      this.headline = key
      await this.trackActionTypeChange(value)
      this.$router
        .push({
          path: this.$route.path,
          query: { ...this.$route.query, actionType: value },
        })
        .catch(() => {})
    },
    async trackActionTypeChange(type) {
      switch (type) {
        case 'ADD_LEAD':
          await trackEvent({ type: 'dashboard-analytics-filtered', props: { actions: 'CONTACTS_ADDED' } })
          break
        case 'SYNC_CONV':
          await trackEvent({ type: 'dashboard-analytics-filtered', props: { actions: 'MESSAGES_SENT' } })
          break
        case 'VIEW_LEAD':
          await trackEvent({ type: 'dashboard-analytics-filtered', props: { actions: 'PROFILES_VIEWED' } })
          break
        case 'ADD_COMPANY':
          await trackEvent({ type: 'dashboard-analytics-filtered', props: { actions: 'COMPANIES_ADDED' } })
          break
        default:
          break
      }
    },
    resetActionType() {
      this.headline = 'Filter Actions'
      const query = { ...this.$route.query }
      delete query.actionType
      this.$router.replace({ query })
      this.headline = 'Filter Actions'
      this.$store.dispatch('api/loadActions', {
        actionType: '',
        duration: this.$route.query.duration,
      })
    },
  },
}
</script>

<template>
  <div>
    <b-card
      v-if="data"
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>Statistics</b-card-title>
        <b-card-text class="mr-1 mb-0 text-info text-lighten-3 font-small-2">
          Updated 1 day ago
        </b-card-text>
      </b-card-header>

      <b-card-body
        class="statistics-body pt-2"
        style="padding-top:4px!important"
      >
        <b-row>
          <b-col
            v-for="item in data"
            :key="item.icon"
            xl="3"
            sm="6"
            :class="item.customClass"
            class="mb-1"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>

              <b-media-body class="my-auto">
                <p class="font-weight-bolder font-small-3 mb-0 text-info text-darken-1">
                  {{ item.title }}
                </p>

                <b-card-text class="font-small-3 mb-0 text-info text-lighten-2">
                  {{ item.subtitle }}
                </b-card-text>
                <b-button
                  v-if="item.subtitle === 'Enrichments done'"
                  v-b-modal.enrichment-stats
                  class="font-small-3 text-weight-bold mb-0 p-0"
                  variant="link"
                >
                  More Stats
                </b-button>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <enrichment-statistics-modal />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BButton,
} from 'bootstrap-vue'
import EnrichmentStatisticsModal from './EnrichmentStatisticsModal.vue'

export default {
  components: {
    EnrichmentStatisticsModal,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BButton,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

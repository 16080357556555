<template>
  <b-modal
    id="enrichment-stats"
    title="More stats"
    ok-title="Close"
    ok-only
    centered
  >
    <div class="m-1">
      <h4 class="mb-1">
        Email Finder Statistics
      </h4>
      <span>
        <p>
          Total number of emails found: <b class="text-primary">{{ totalEmails }}</b>
        </p>
        <p
          v-for="item in account.emailEnrichmentStats"
          :key="item.provider"
        >
          {{ item.provider }}: <b>{{ Math.round((item.count / totalEmails) * 1000) / 10 }}%</b>
        </p>
      </span>
    </div>
    <div class="m-1 mt-4">
      <h4 class="mb-1">
        Phone Finder Statistics
      </h4>
      <span>
        <p>
          Total number of phones found: <b class="text-primary">{{ totalPhones }}</b>
        </p>
        <p
          v-for="item in account.phoneEnrichmentStats"
          :key="item.provider"
        >
          {{ item.provider }}: <b>{{ Math.round((item.count / totalPhones) * 1000) / 10 }}%</b>
        </p>
      </span>
    </div>
  </b-modal>
</template>

<script>

import { mapState } from 'vuex'

export default {
  data() {
    return { totalEmails: 0, totalPhones: 0 }
  },
  computed: {
    ...mapState({
      account: state => state.api.account,
    }),
  },
  mounted() {
    this.totalEmails = (this.account.emailEnrichmentStats ?? []).reduce((a, b) => a + b.count, 0)
    this.totalPhones = (this.account.phoneEnrichmentStats ?? []).reduce((a, b) => a + b.count, 0)
  },
}
</script>

<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="font-weight-bolder">
          Top Industries
        </b-card-title>
      </div>
    </b-card-header>
    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="350"
        :options="barChart.chartOptions"
        :series="barChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      barChart: {
        series: [
          {
            name: 'Records',
            data: this.data.values,
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: '#889BA0',
          plotOptions: {
            bar: {
              horizontal: false,
              barHeight: '30%',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: this.data.categories,
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
    }
  },
}
</script>
